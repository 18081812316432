import React from 'react'
import { Col, Flex, Form, Image, Input, Row, Typography } from 'antd'

const Instructor = () => {
    const [form] = Form.useForm()
  return (
    <Flex className='mt-5 py-3'>
        <div className='width-93'>
            <Row gutter={[24,24]} align={'middle'} justify={'space-between'}>
                <Col xs={24} sm={24} md={12}>
                    <Typography.Title className='primary-heading m-0 mb-3 text-center' level={1}>
                        Instructor.
                    </Typography.Title>
                    <Flex justify='center'>
                        <Image src='/assets/instructor.png' preview={false} width={'70%'} />
                    </Flex>
                </Col>
                <Col xs={24} sm={24} md={10}>
                    <Typography.Text className='body-text text-justify mb-3'>
                        Please provide your details to set up your account:
                    </Typography.Text>
                    <Form
                        form={form}
                        layout='vertical'
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    className='body-text'
                                    label="First name"
                                    name="firstName"
                                    >
                                    <Input className='long-field' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    className='body-text'
                                    label="Last name"
                                    name="lastName"
                                    >
                                    <Input className='long-field' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    className='body-text'
                                    label="Email"
                                    name="email"
                                    >
                                    <Input className='long-field' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    className='body-text'
                                    label="University Profile Website"
                                    name="upw"
                                    >
                                    <Input className='long-field' />
                                </Form.Item>
                            </Col>
                            <Col span={24} className='mb-3'>
                                <Typography.Title className='secondary-heading m-0 mb-3' level={3}>
                                    To create a class account.
                                </Typography.Title>
                                <Typography.Text className='body-text text-justify'>
                                    If you are setting up an account for your class, please provide the following:
                                </Typography.Text>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    className='body-text'
                                    label="Number of students in your class"
                                    name="noofstudent"
                                    >
                                    <Input className='long-field' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    className='body-text'
                                    label="Class name"
                                    name="className"
                                    >
                                    <Input className='long-field' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    </Flex>
  )
}

export {Instructor}