import React from 'react'
import { Button, Modal, Space, Form, Input } from 'antd'

const AddLabelModal = ({visiblemodal, onClose}) => {

    const [form] = Form.useForm()

  return (
    <Modal width={500} 
        className='shadow-c modal'  
        title='Add Label'
        open={visiblemodal} 
        onOk={onClose} 
        onCancel={onClose} 
        centered 
        footer={
            <Space style={{marginTop:'15px'}} className="w-100">
                <Button onClick={()=>form.submit()} className='sm-btn brand-bg text-white'>
                    Add
                </Button>
                <Button onClick={onClose} className='sm-btn'>
                    Cancel
                </Button>
            </Space>
        }
    >
        <Form
                form={form}
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                // onFinish={onFinish}
            >
                <Form.Item
                    className='body-text'
                    label="Create new Label to recognize"
                    name="password"
                    >
                    <Input className='long-field w-100' />
                </Form.Item>
        </Form>            
    </Modal>
  ) 
}

export {AddLabelModal}