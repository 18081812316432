import React from 'react'
import { Button, Col, Flex, Image, Row, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

const WelcomeSection = () => {
    const navigate = useNavigate()

    return (
      <Flex justify='center'>
        <div className='width-93'>
          <Row gutter={[24,24]} align={'middle'}>
            <Col xs={24} sm={24} md={12}>
              <div className='center'>
                <Image src='/assets/robot.png' preview={false} width={'70%'} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <Typography.Title className='primary-heading' level={1}>
                  Welcome to MLScholar!
                </Typography.Title>
                <Typography.Title className='primary-heading' level={1}>
                  Get Started with Machine Learning
                </Typography.Title>
                <Typography.Title className='primary-heading' level={1}>
                  Ready to dive into the world of machine learning? Start your journey now!
                </Typography.Title>
                <Button onClick={()=>navigate('/')} className='larg-btn brand-bg text-white'>
                  Log In
                </Button>
            </Col>
          </Row>
        </div>
      </Flex>
    )
}

export {WelcomeSection}