import { Button, Card, Flex, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './index.css'

const Register = () => {

    const navigate = useNavigate()

  return (
    <Flex className='h-100vh' align='center' justify='center'>
        <div className='reg-Form'>
            <Card className='card-reg'>
                <Typography.Title level={3} className='secondary-heading m-0'>
                    Register.
                </Typography.Title>
                <Typography.Title level={1} className='primary-heading mb-3 mt-0'>
                    Welcome to MLScholar!
                </Typography.Title>
                <Typography.Text className='body-text block  mb-3'>
                    We are thrilled to have you join our community. To get started, please tell us who are:
                </Typography.Text>
                <Flex className='mb-3' vertical gap="large">
                    <Button onClick={()=>navigate('/instructor')} className='sm-btn brand-bg text-white'>
                        Instructor
                    </Button>
                    <Button onClick={()=>navigate('/student')} className='sm-btn brand-bg text-white'>
                        Student
                    </Button>
                </Flex>
            </Card>
        </div>
    </Flex>
  )
}

export {Register}