import MLRouter from './MLRouter';

function App() {
  return (
    <div>
      <MLRouter />
    </div>
  );
}

export default App;
