import { Button, Card, Col, Flex, Row, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
// import { AddLabelCard } from '../../AddLabelCard'

const CollectTrainComponent = () => {

    const navigate = useNavigate()

    const data = [
        {
            title:"Collect Samples.",
            desc:"Start by gathering examples of the image you want the system to recognize.",
            btntext:"Collect",
            path:"/collectsample"
        },
        {
            title:"Train and Test.",
            desc:"Use the examples to teach your machine learning how to identify these images and verify how well your model performs.",
            btntext:"Train",
            path:"/train"
        },
    ]

  return (
    <Flex justify='center' className='h-100vh' align='center'>
        <div className='width-93'>
            <Row gutter={[16,16]} justify='center'>
                {
                    data?.map((dat,d)=>
                        <Col xs={24} sm={24} md={12} lg={10} key={d}> 
                            <Card className='card-login'>
                                <Typography.Title level={2} className='secondary-heading text-center m-0 mb-3'>
                                    {
                                        dat?.title
                                    }
                                </Typography.Title>
                                <Typography.Text className='body-text text-justify mb-3'>
                                    {
                                        dat?.desc
                                    }
                                </Typography.Text>
                                <Button onClick={()=>navigate(dat?.path)} className='sm-btn brand-bg text-white'>
                                    {
                                        dat?.btntext
                                    }
                                </Button>
                            </Card>
                        </Col>
                    )
                }
                {/* <Col xs={24} sm={24} md={8}>
                    <AddLabelCard />
                </Col> */}
            </Row>
        </div>
    </Flex>
  )
}

export {CollectTrainComponent}