import React from 'react'
import { Button, Card, Col, Flex, Form, Input, Row, Typography } from 'antd'
import './index.css'

const Login = () => {

    const [form] = Form.useForm()

  return (
    <Flex className='h-100vh' justify='center' align='center'>
        <div className='Form'>
            <Card className='cs-login'>
                <Typography.Title level={1} className='primary-heading text-center mb-3'>
                    Login
                </Typography.Title>
                <Form
                        form={form}
                        layout='vertical'
                    >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                className='body-text'
                                label="Username"
                                name="userName"
                                >
                                <Input className='long-field w-100' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className='body-text'
                                label="Password"
                                name="password"
                                >
                                <Input className='long-field w-100' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button className='sm-btn brand-bg text-white'>
                                Log In
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    </Flex>
  )
}

export {Login}