import React from 'react'
import { Button, Flex, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

const InitialStage = () => {

    const navigate = useNavigate()

  return (
    <Flex justify='center'>
        <div className='width-93'>
            <Flex gap='large' align='center' vertical justify='center' className='h-100vh'>
                <Typography.Title className='primary-heading m-0 mb-3 text-center' level={1}>
                    Your Projects.
                </Typography.Title>
                <Button onClick={()=>navigate('/projectname')} className='larg-btn brand-bg text-white'>
                    Create a new project
                </Button>
            </Flex>
        </div>
    </Flex>
  )
}

export {InitialStage}