import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AboutUs, CourseWork, HomePage, Instructor, Login, LoginIntro, Project, Register, Student } from '../pages';
import Navbar from '../components/Navbar';
import { CollectSample, CollectTrainComponent, ProjectNameForm, StartTraining, Training } from '../components';

const MLRouter = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>        
        <Route path="/" element={<LoginIntro />} />
        <Route path="/homepage" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/instructor" element={<Instructor />} />
        <Route path="/student" element={<Student />} />
        <Route path="/project" element={<Project />} />
        <Route path="/projectname" element={<ProjectNameForm />} />
        <Route path='/collecttrain' element={<CollectTrainComponent />} />
        <Route path='/collectsample' element={<CollectSample />} />
        <Route path='/train' element={<Training />} />
        <Route path='/starttrain' element={<StartTraining />} />
        <Route path='/coursework' element={<CourseWork />} />
        <Route path="/" element={<AboutUs />} />
      </Routes>
    </BrowserRouter>
  )
}

export default MLRouter