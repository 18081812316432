import React from 'react'
import { Button, Card, Col, Flex, Form, Input, Row, Typography } from 'antd'
import './index.css'
import { useNavigate } from 'react-router-dom'

const ProjectNameForm = () => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
  return (
    <Flex className='h-100vh' justify='center' align='center'>
        <div className='pro-Form'>
            <Card className='card-login'>
                <Typography.Title level={1} className='primary-heading text-center m-0 mb-3'>
                    Your Projects.
                </Typography.Title>
                <Form
                        form={form}
                        layout='vertical'
                    >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                className='body-text'
                                label="Project name"
                                name="projectName"
                                >
                                <Input className='long-field w-100' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Flex justify='end' gap='small'>
                                <Button onClick={()=>navigate('/collecttrain')} className='sm-btn brand-bg text-white'>
                                    Create
                                </Button>
                                <Button onClick={()=>navigate(-1)} className='sm-btn '>
                                    Cancel
                                </Button>
                            </Flex>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    </Flex>
  )
}

export {ProjectNameForm}