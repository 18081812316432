import { Modal, Space, Typography, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';
const AlertModal = ({confirmmodal,onClose }) => {
   
    
  return (
    <div>
        <Modal width={500} className='shadow-c modal'  open={confirmmodal} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <DeleteOutlined 
                    style={{fontSize: '40px'}} 
                />
                <Typography.Title level={3} className='primary-heading m-0'>Are you sure?</Typography.Title>
                <Typography.Text className='body-text text-center'>Do you want to delete meeting with name? (This cannot be undone)</Typography.Text>
                <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                    <Button onClick={onClose} className='sm-btn'>
                        Cancel
                    </Button>
                    <Button onClick={()=>{}} className='sm-btn brand-bg text-white'>
                        Confirm
                    </Button>
                </Space>
            </Space>
        </Modal>
    </div>
  )
}

export {AlertModal}