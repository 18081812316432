import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import  './index.css'
import {Typography, Image} from  'antd'
import {
    CloseOutlined
} from '@ant-design/icons'

const {Text}= Typography
class Navbar extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            IsNavShowing:false
        }
    }
    showLargeNav=()=>{
        var navbarSmall=document.getElementsByClassName("ml-navbar-small")[0];
        navbarSmall.setAttribute("class","ml-navbar-small animation bg-white ml-navbar-small-move")
        var navbar=document.getElementsByClassName("ml-navbar")[0];
        navbar.setAttribute('class',"ml-navbar secondary-bg ml-navbar-trans")
        this.setState({IsNavShowing:!this.state.IsNavShowing})
    }
    hideLargeNav=()=>{
        var navbarSmall=document.getElementsByClassName("ml-navbar-small")[0];
        navbarSmall.setAttribute("class","ml-navbar-small bg-white animation")
        var navbar=document.getElementsByClassName("ml-navbar")[0];
        navbar.setAttribute('class',"ml-navbar")
        this.setState({IsNavShowing:!this.state.IsNavShowing})
    }
    render()
    {
        return  (
            <>
            <div  className='ml-navbar-container' style={{position:'relative'}}>
                <div className='w-100'>
                    <div className="ml-navbar-small bg-white">
                        <div className="ml-navbar-inner">
                            <div className='ml-navbar-left'>
                                <NavLink to="/homepage" >
                                    <Image src={'/assets/logo.jpg'} alt='' width={'70%'} preview={false}/>
                                </NavLink>
                            </div>
                            <div className="ml-navbar-right">
                                <div className="sp-cover" onClick={this.showLargeNav}>
                                    <span className="sp sp-1 sp-1-click"></span>
                                    <span className="sp sp-2 sp-2-click"></span>
                                    <span className="sp sp-3 sp-3-click"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ml-navbar'>
                    <div className="ml-navbar-inner">
                        <div className='ml-navbar-left'>
                            <NavLink to="/homepage" >
                                <Image src='/assets/logo.jpg' width={'70%'} preview={false} className='one'/>
                            </NavLink>
                            <CloseOutlined onClick={this.hideLargeNav} className='two'/>
                        </div>
                        <div className='width-93'>
                            <ul className="ml-navbar-middle" style={{height:'100%'}}>
                                <li className='items'>
                                    <NavLink to="/" className="nav-item" >
                                        <div className='align-center'>
                                            <Text className='my-0 item-name'>About</Text>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className='items'>
                                    <NavLink to="/project" className="nav-item" >
                                        <div className='align-center'>
                                            <Text className='my-0 item-name'>Projects</Text>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className='items'>
                                    <NavLink to="/coursework" className="nav-item" >
                                        <div className='align-center'>
                                            <Text className='my-0 item-name'>CourseWork</Text>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className='items'>
                                    <NavLink to="/" className="nav-item" >
                                        <div className='align-center'>
                                            <Text className='my-0 item-name'>Logout</Text>
                                        </div>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
    componentDidMount(state)
    {
        var navbar=document.getElementsByClassName("ml-navbar")[0];
        var navbarLeft=document.getElementsByClassName("ml-navbar-left")
        var navbarSmall=document.getElementsByClassName("ml-navbar-small")[0];

      
        const responsive=()=>
        {
           
        }
        responsive();
        window.addEventListener("resize",()=>{responsive(); scrol();})
        let upDown;
        function scrol()
                {
                    console.log(window.pageYOffset)
                    if(window.innerWidth>970)
                    {
                        if(window.pageYOffset>upDown)
                        {
                            navbarLeft[1].style.width='100px'
                            navbar.setAttribute("class","ml-navbar animation bg-white active");
                        }
                        else if(window.pageYOffset<11)
                        {
                            navbarLeft[1].style.width='100px'
                            navbar.setAttribute("class","ml-navbar")
                        }
                        else
                        {
                            navbarLeft[1].style.width='100px'
                            navbar.setAttribute("class","ml-navbar animation bg-white active")
                        }
                    }
                    else
                    {
                        navbar.setAttribute("class","ml-navbar");
                        if(window.pageYOffset>upDown)
                        {
                            navbarLeft[0].style.width='100px'
                            navbarSmall.setAttribute("class","ml-navbar-small secondary-bg animation ml-navbar-small-move")
                        }
                        else if(window.pageYOffset<11)
                        {
                            navbarLeft[0].style.width='100px'
                            navbarSmall.setAttribute("class","ml-navbar-small")
                        }
                        else
                        {
                            navbarLeft[0].style.width='100px'
                            navbarSmall.setAttribute("class","ml-navbar-small bg-white animation")
                        }
                    }
                    upDown=window.pageYOffset
                   
                }  
                scrol();
                window.onscroll = function() {scrol()}
    }
}
export default Navbar;
