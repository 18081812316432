import React from 'react'
import { Col, Flex, Image, Row, Typography } from 'antd'

const AboutComponent = () => {

  const data = {
    title:"About MLScholar",
    desc:"MLScholar makes machine learning accessible and engaging. Whether you're tackling a marketing class project or exploring data science, our platform allows you to interactively train and test supervised machine learning models with real-world applications. MLScholar equips you with the tools to apply theoretical knowledge in practical scenarios, enhancing your learning and your projects"
  }

  return (
    <Flex justify='center' className='secondary-bg mt-5 py-3'>
      <div className='width-93'>
        <Row gutter={[24,24]} align={'middle'} justify={'space-between'}>
            <Col xs={24} sm={24} md={10}>
              <Typography.Title className='primary-heading m-0 mb-3' level={1}>
                  {
                    data?.title
                  }
              </Typography.Title>
              <Typography.Text className='body-text text-justify'>
                  {
                    data?.desc
                  }
              </Typography.Text>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Flex justify='center'>
                <Image src='/assets/ab-1.png' preview={false} width={'70%'} />
              </Flex>
            </Col>
        </Row>
      </div>
    </Flex>
  )
}

export {AboutComponent}