import React from 'react'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'

const WhyChoose = () => {

    const data = {
      title:"Why Choose MLScholar?",
      desc:[
        {
          text:"Interactive Learning: Hands-on projects to apply machine learning concepts."
        },
        {
          text:"Practical Applications: Utilize machine learning in practical scenarios like brand logo recognition to see how theories work outside the classroom."
        },
        {
          text:"User-Friendly: Designed with students in mind, making complex algorithms approachable."
        },
        {
          text:"Begin your machine learning adventure with MLScholar and bring your classroom knowledge to life!"
        },
      ]
    }

    return (
        <Flex justify='center' className='bg-tertiary py-3'>
          <div className='width-93'>
            <Row gutter={[24,24]} align={'middle'} justify={'space-between'}>
                <Col xs={24} sm={24} md={10}>
                  <Flex align='center' justify='center'>
                    <Image src='/assets/stand.png' preview={false} width={'70%'} />
                    <Image src='/assets/wave.png' preview={false} width={'70%'} />
                  </Flex>
                </Col>
                <Col xs={24} sm={24} md={10}>
                    <Space direction='vertical' size={10}>
                        <Typography.Title className='primary-heading m-0 mb-3' level={1}>
                            {
                              data?.title
                            }
                        </Typography.Title>
                        {
                          data?.desc?.map((data,d)=>
                            <Typography.Text className='body-text text-justify' key={d}>
                              {
                                data?.text
                              }
                            </Typography.Text>
                          )
                        }
                    </Space>
                </Col>
            </Row>
          </div>
        </Flex>
      )
}

export {WhyChoose}

