import React, { useState } from 'react'
import { Button, Card, Col, Flex, Image, Row, Tooltip, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { AddLabelModal } from '../../AddLabelModal'
import { DeleteOutlined } from '@ant-design/icons'
import { AlertModal } from '../../AlertModal'

const CollectSample = () => {

    const navigate = useNavigate()
    const [ visiblemodal, setVisibleModal ] = useState(false)
    const [ confirmmodal, setConfrimModal ] = useState(false)

  return (
    <Flex justify='center' className='mar-10 py-3'>
        <div className='width-93'>
            <Row gutter={[16,24]} justify='center'>
                <Col span={24}>
                    <Typography.Title className='primary-heading m-0 mb-3 text-center' level={1}>
                        Collect Samples.
                    </Typography.Title>
                </Col>
                <Col span={24}>
                    <Flex gap='large' align='center' justify='space-between'>
                        <Button onClick={()=>navigate(-1)} className='sm-btn'>
                            Back
                        </Button>
                        <Button onClick={()=>setVisibleModal(true)} className='sm-btn brand-bg text-white'>
                            Add Label
                        </Button>
                    </Flex>
                </Col>
                <Col xs={24} sm={24} md={20}>
                    <Card className='secondary-bg mb-3' style={{position:'relative'}}>
                        <Typography.Title level={2} className='primary-heading m-0'>Test Project</Typography.Title>
                        <Flex wrap gap={10} align='center' className='pt-2'>
                            <Typography.Text className='secondary-subheading'>
                                Recognizing
                            </Typography.Text>
                            <Typography.Title level={4} 
                                className='secondary-subheading brand-color m-0'>
                                Image
                            </Typography.Title>
                            <Typography.Text className='secondary-subheading'>
                                as
                            </Typography.Text>
                            <Typography.Title level={4} 
                                className='secondary-subheading brand-color m-0 text-justify'>
                                Lorem ipsum dolor
                            </Typography.Title>
                        </Flex>
                        <Tooltip title='Delete'>
                            <Button 
                                danger 
                                onClick={()=> setConfrimModal(true)} 
                                className='cs-delete' 
                                shape="circle" 
                                icon={<DeleteOutlined color='danger' />} 
                            />
                        </Tooltip>
                        <Flex justify='end'>
                            <div className='data-storage'>
                                <Image src='/assets/local.png' width={24} preview={false} />
                            </div>
                        </Flex>
                    </Card>
                </Col>
            </Row>
        </div>
        <AddLabelModal 
            visiblemodal={visiblemodal}
            onClose={()=>setVisibleModal(false)}
        />
        <AlertModal 
            confirmmodal={confirmmodal}
            onClose={()=>setConfrimModal(false)}
        />
    </Flex>
  )
}

export {CollectSample}